// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actus-fr-js": () => import("./../src/pages/actus.fr.js" /* webpackChunkName: "component---src-pages-actus-fr-js" */),
  "component---src-pages-commercants-fr-js": () => import("./../src/pages/commercants.fr.js" /* webpackChunkName: "component---src-pages-commercants-fr-js" */),
  "component---src-pages-handelaren-nl-js": () => import("./../src/pages/handelaren.nl.js" /* webpackChunkName: "component---src-pages-handelaren-nl-js" */),
  "component---src-pages-index-fr-js": () => import("./../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-nl-js": () => import("./../src/pages/index.nl.js" /* webpackChunkName: "component---src-pages-index-nl-js" */),
  "component---src-pages-news-nl-js": () => import("./../src/pages/news.nl.js" /* webpackChunkName: "component---src-pages-news-nl-js" */),
  "component---src-templates-categorie-template-js": () => import("./../src/templates/CategorieTemplate.js" /* webpackChunkName: "component---src-templates-categorie-template-js" */),
  "component---src-templates-commercant-template-js": () => import("./../src/templates/CommercantTemplate.js" /* webpackChunkName: "component---src-templates-commercant-template-js" */),
  "component---src-templates-news-template-js": () => import("./../src/templates/NewsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-page-template-js": () => import("./../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

